import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Home() {


  useEffect(() => {
    document.title = "Hälsinglands Multisportklubb";
    
  }, []);

  return (
    <>
      <article>
        <h1>Hälsinglands Multisport</h1>
        <p>
          En klubb med säte i Söderhamn som vurmar för spontanidrott och naturnära upplevelser.
          Traillöpning, MTB/Gravel-cykling, kajakpaddling och orientering är det vi utöver mest men alla naturnära aktiviteter är intressanta.
          Allt från vandring till extrema varianter av multisport får plats hos oss.
        </p>
        <p>
          Vi håller igång cykel- och vandringslederna runt Stormorshäll, arrangerar terrängloppen Hälsingetrailen och Stormorstrailen 
          samt <a href="//hex.hälsinglandsmultisport.se" target="_blank">multisporteventet HEX</a>. 
          Klubben är även med i samarbetet Hällmyra Arena, ett initiativ för att utveckla Hällmyraområdet.<br />
          Utöver detta så bjuder vi ständigt in till andra spontana träffar för löpning, cykling och andra äventyr.
        </p>
        <p>
          Vårt motto är att <em>alla kan vara med</em>.
        </p>
        

      </article>
      <article>
        <figure>
          <NavLink to="/halsingetrailen"><img src="/images/halsingetrailen_logo.png" alt="Hälsingetrailen" /></NavLink>
          <figcaption>Hälsingetrailen</figcaption>
        </figure>
        <figure>
          <NavLink to="/stormorstrailen"><img src="/images/stormorstrailen_logo.png" alt="Stormorstrailen" /></NavLink>
          <figcaption>Stormorstrailen</figcaption>
        </figure>
        <figure>
          <NavLink to="/hallmyra"><img src="/images/hallmyra_arena_logo.png" alt="Hällmyra Arena" /></NavLink>
          <figcaption>Hällmyra Arena</figcaption>
        </figure>
        <figure>
          <a href="//helsingecupen.se"  target="_blank"><img src="/images/helsingecupen_logo.png" alt="Helsingecupen XC" /></a>
          <figcaption>Helsingecupen XC</figcaption>
        </figure>
      </article>
    </>
  )
}