export function useApi() {

  
  const baseUrl = 'https://stigloparn.se:17655';
  const token = {bearer:''}

  const Api = {

    // Normal GET request
    get: (url, options) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${token.authToken}`
        }
        ,
        ...options
      };
      return fetch(baseUrl + url, requestOptions).then(Api.handleResponse);

    },
    // Normal POST request
    post: (url, body, options) => {
      //console.log('post', url, body);
      const requestOptions = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${token.authToken}`
        },
        body: JSON.stringify(body),
        ...options
      };
      return fetch(baseUrl + url, requestOptions).then(Api.handleResponse);
    },
    // Normal PUT request
    put: (url, body, options) => {
      //console.log('put', url, body);
      const requestOptions = {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${token.authToken}`
        },
        body: JSON.stringify(body),
        ...options
      };
      return fetch(baseUrl + url, requestOptions).then(Api.handleResponse);

    },
    // Normal DELETE request
    delete: (url, body, options) => {
      //console.log('delete', url, body);

      const requestOptions = {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${token.authToken}`
        },
        body: JSON.stringify(body),
        ...options
      };
      return fetch(baseUrl + url, requestOptions).then(Api.handleResponse);

    },
    // Filter results from requests and checks of status is OK (HTTP 200)
    handleResponse: (response) => {
      return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
          const error = (data && data.errorMessage) || response.statusText;
          return Promise.reject({ status: response.status, error: error });
        }
        return data;
      });
    },
  }


  return Api;

}
