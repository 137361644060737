import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Klubben() {

  useEffect(() => {
    document.title = "Klubben | Hälsinglands Multisportklubb";
    document.getElementById("main")?.scrollIntoView(  { behavior: 'smooth' })
  }, []);

  return (
    <>
      <article id="mainContent">

        <h1>Klubben</h1>
        <figure>
          <img src="/images/paddling.jpg" alt="Kajakpaddling, HEX Supem" />
          <figcaption>Kajakpaddling, HEX Supem</figcaption>
        </figure>
        <p>
          Hälsinglands Multisport fungerar inte riktigt som andra idrottsföreningar gör. Vi har inga regelrätta tränarledda träningar,
          ingen ungdomsverksamhet eller elitverksamhet, vi håller inte på med bingolottoförsäljning och vi har inte ett rullande schema för kioskbemanningen.
        </p>
        <p>
          Vad vi däremot har är jäkligt roligt och en fantastisk kärlek till det vi håller på med, vare sig det är fjällöpning, mountainbikecykling eller skogspromenader.
          I vår värld finns inga rätt eller fel, inga pekpinnar eller någon som talar om för dig vad som krävs.
        </p>
        <p>
          Vi älskar att se när människor utmanar sig själva, utifrån sina egna förutsättningar. För en del handlar det om att springa 100 miles,
          för andra att bara ta sig utanför dörren. Därför försöker vi alltid hålla våra arrangemang så öppna som möjligt för att
          just alla ska kunna vara med på sina egna villkor.
        </p>

        <p>
          Så nästa gång du ser att vi bjuder in till någon gemensam träning eller vad det nu kan vara, följ med!
        </p>
        <h4>Kontakt</h4>
        <p>
          Har du några frågor är du välkommen att kontakta oss.<br />
          <a href="mailto:info@hälsinglandsmultisport.se"><span className="fas fa-envelope"></span> info@hälsinglandsmultisport.se</a>
        </p>

      </article>
      <nav>
        <NavLink to="/klubben/historia">Historia</NavLink>
        <NavLink to="/klubben/medlemskap">Medlemskap</NavLink>
      </nav>
    </>
  )
}