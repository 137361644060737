import { useEffect } from "react";
import { BrowserRouter, Link, NavLink, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Aktuellt from "./Aktuellt/Aktuellt";
import Hallmyra from "./Hallmyra/Hallmyra";
import Leder from "./Hallmyra/Leder";
import Halsingetrailen from "./Halsingetrailen/Halsingetrailen";
import Resultat from "./Halsingetrailen/Resultat";
import Home from "./Home";
import Historia from "./Klubben/Historia";
import Klubben from "./Klubben/Klubben";
import Medlemskap from "./Klubben/Medlemskap";
import Styrelsen from "./Klubben/Styrelsen";
import Stormorstrailen from "./Stormorstrailen/Stormorstrailen";

export default function App() {

  

  return (<>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<Home />} />

            <Route path="aktuellt" element={<Aktuellt/>} />

            <Route path="klubben">
              <Route index element={<Klubben />} />
              <Route path="historia" element={<Historia />} />
              <Route path="medlemskap" element={<Medlemskap />} />
              <Route path="styrelsen" element={<Styrelsen />} />
            </Route>

            <Route path="halsingetrailen">
              <Route index element={<Halsingetrailen />} />
              <Route path="resultat" element={<Resultat />} />
            </Route>

            <Route path="stormorstrailen" element={<Stormorstrailen />} />

            <Route path="hallmyra" >
              <Route index element={<Hallmyra />} />
              <Route path="leder" element={<Leder />} />
            </Route>

            <Route path="klubben.html" element={<Klubben />} />
            <Route path="leder.html"  element={<Leder />} />
            <Route path="medlemskap.html"  element={<Medlemskap />} />
            <Route path="medlemskap"  element={<Medlemskap />} />
            
            <Route path="stormorstrailen.html"  element={<Stormorstrailen />} />
            <Route path="halsingetrailen.html"  element={<Halsingetrailen />} />
            <Route path="halsingetrailen_resultat.html"  element={<Resultat />} />
            <Route path="historia.html"  element={<Historia />} />
            <Route path="stormorshall.html"  element={<Hallmyra />} />
            

            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>);
}

const Layout = () => {

  const location = useLocation();
  useEffect( () => {
    document.getElementById("menuToggle").checked = false;
  }, [location])

  return (<>
    <section role="navigation">

      <nav>
        <input type="checkbox" name="menuToggle" id="menuToggle" />
        <span className="fas fa-bars" id="menuIcon"></span>
        <ul>
          <li className="hideOnMobile">
            <NavLink to="/" ><img src="/images/favicon.png" alt="Hälsinglands Multisport" className="favicon" /></NavLink>
          </li>
          
          <li><NavLink to="/" className={({ isActive }) => isActive ? 'active' : undefined}>Hem</NavLink></li>
          <li><NavLink to="aktuellt" className={({ isActive }) => isActive ? 'active' : undefined}>Aktuellt</NavLink></li>
          <li>
            <NavLink to="/klubben" className={({ isActive }) => isActive ? 'active' : undefined}>Klubben</NavLink>
            <ul>
              <li><NavLink to="/klubben/historia" className={({ isActive }) => isActive ? 'active' : undefined}>Historia</NavLink></li>
              <li><NavLink to="/klubben/medlemskap" className={({ isActive }) => isActive ? 'active' : undefined}>Medlemskap</NavLink></li>
              <li><NavLink to="/klubben/styrelsen" className={({ isActive }) => isActive ? 'active' : undefined}>Styrelsen</NavLink></li>
            </ul>
          </li>

          <li>
            <NavLink to="/halsingetrailen" className={({ isActive }) => isActive ? 'active' : undefined}>Hälsingetrailen</NavLink>
            <ul>
              <li><NavLink to="/halsingetrailen/resultat" className={({ isActive }) => isActive ? 'active' : undefined}>Resultat</NavLink></li>
            </ul>
          </li>
          <li><NavLink to="/stormorstrailen" className={({ isActive }) => isActive ? 'active' : undefined}>Stormorstrailen</NavLink></li>
          <li><NavLink to="/hallmyra" className={({ isActive }) => isActive ? 'active' : undefined}>Hällmyra Arena</NavLink>
            <ul>
              <li><NavLink to="/hallmyra/leder" className={({ isActive }) => isActive ? 'active' : undefined}>Cykel- & vandringsleder</NavLink></li>
            </ul>
          </li>
          <li><a href="//hex.hälsinglandsmultisport.se">HEX</a></li>
        </ul>
      </nav>
    </section>

    <header className="intro">
      <img className="logo shadow" src="/images/hmsklogovit.png" alt="Hälsinglands Multisport" />
      <img className="logo" src="/images/hmsklogo.png" alt="Hälsinglands Multisport" />

      <video autoPlay loop muted>
        <source src="/images/intro.mp4" />
      </video>
    </header>

    <main id="main">
      <Outlet />
    </main>
    <footer>
      <a href="https://www.facebook.com/HalsinglandsMultisport/" target="_blank"><span className="fab fa-facebook"></span> Hälsinglands Multisport</a>
      <a href="https://www.facebook.com/stormorshall/" target="_blank"><span className="fab fa-facebook"></span> Stormorshäll</a>
      <a href="https://www.facebook.com/halsingetrailen/" target="_blank"><span className="fab fa-facebook"></span> Hälsingetrailen</a>
      <a href="https://www.instagram.com/halsingetrailen/" target="_blank"><span className="fab fa-instagram"></span> Hälsingetrailen</a>
      <a href="mailto:info@hälsinglandsmultisport.se"><span className="fas fa-envelope"></span> info@hälsinglandsmultisport.se</a>
    </footer>
  </>);
}

const NoMatch = () => {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}