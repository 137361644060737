import { useEffect } from "react";

export default function Hallmyra() {

  useEffect(() => {
    document.title = "Hällmyra Arena | Hälsinglands Multisportklubb";
    document.getElementById("main")?.scrollIntoView({ behavior: 'smooth' })
  }, []);

  return (
    <>
      <article id="mainContent">
        <figure>
          <img src="/images/hallmyra_arena_logo.png" alt="Hällmyra Arena" />
          <figcaption>Hällmyra Arena</figcaption>
        </figure>
        <h1>Hällmyra Arena</h1>

        <p>
          Sommaren 2023 slog vi våra påsar ihop med Söderhamns Skidkamrater samt Skärgårdens Multisportförening och bildade Hällmyra Arena.
        </p>
        <p>
          Hällmyra Arena är ett initiativ för att utveckla Hällmyraområdet till en mötesplats för idrott och friluftsliv. På kort sikt innebär det att föreningarna kommer att
          fokusera sina arrangemant till området. På längre sikt hoppas vi kunna utveckla området till en plats där alla kan hitta något att göra.
        </p>
        <p>
          Vill du vara med och utveckla Hällmyraområdet? Hör av dig till oss!
        </p>
        <p>
          <a href="https://facebook.com/hallmyraarena" target="_blank">Hällmyra Arena på Facebook</a>
        </p>
      </article>
    </>
  )
}