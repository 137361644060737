import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Historia() {

  useEffect(() => {
    document.title = "Klubbens historia | Hälsinglands Multisportklubb";
    document.getElementById("main")?.scrollIntoView(  { behavior: 'smooth' })
  }, []);

  return (
    <>
      <article id="mainContent">

        <h1>Historia</h1>
        <figure>
          <img src="/images/tomaskennet.jpg" alt="Tomas Svedberg och Kennet Bolinder" />
          <figcaption>Tomas Svedberg och Kennet Bolinder</figcaption>
        </figure>
        <p>
          En kall vinterkväll, -25 grader celsius, 1,5 meter snö, med svetten rinnande i en bastu i vemdalsfjällen togs ett stort beslut av Kennet och Tomas.
          Datumet har vi glömt men året var 2009 och då tog vi beslutet att starta en klubb där vi kunde utföra den sport vi älskar.
        </p>
        <p>
          På papperet startade klubben 2009-03-21.
        </p>
      </article>
      <nav>
        <NavLink to="/klubben">Mer om klubben</NavLink>
        <NavLink to="/klubben/medlemskap">Medlemskap</NavLink>
      </nav>
    </>
  )
}