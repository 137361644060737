import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Halsingetrailen() {

  useEffect(() => {
    document.title = "Hälsingetrailen | Hälsinglands Multisportklubb";
    document.getElementById("main")?.scrollIntoView(  { behavior: 'smooth' })
  }, []);

  return (
    <>
      <article id="mainContent">

        <h1>Hälsingetrailen</h1>
        <figure>
          <img src="/images/halsingetrailen_cover.jpg" alt="Hälsingetrailen innan start" />
          <figcaption>Hälsingetrailen innan start</figcaption>
        </figure>


        <h4>Sveriges kanske billigaste motionslopp</h4>
        <p>
          2020 fyllde Hälsingetrailen 10 år! Dessvärre kunde vi inte arrangera på grund av Covid-19 utan ställer in siktet på 2023 i stället.<br />
          Det firar vi med att sänka den redan låga avgiften från 150:- till 100:-.
          Barn och ungdomar under 15 springer gratis.
        </p>
        <p>
          Utöver det lovar vi att bjuda på lite extra festligheter under kvällen.
          På Hälsingetrailens Facebooksida uppdaterar vi med nyheter och annat inför tävlingen.
        </p>
        <nav>
          <a href="https://www.facebook.com/halsingetrailen" target="_blank"><span className="fab fa-facebook"></span> Hälsingetrailen</a>
          <a href="https://www.instagram.com/halsingetrailen" target="_blank"><span className="fab fa-instagram"></span> Hälsingetrailen</a>
        </nav>
        <h4>Om loppet</h4>
        <p>
          Hälsingetrailen är ett motionsterränglopp i hjärtat av Hälsinglands skogar som arrangeras av Hälsinglands Multisport. I Segersta får du en riktig utmaning i allt från obanad terräng till skogsstigar och grusväg. Vägvalen är föredömligt snitslade. De längre sträckorna är riktigt kuperade och ger även den erfarne terränglöparen en riktig match.
          Sprintbanan gör att alla kan vara med och springa!
        </p>
        <p>
          Sträckorna som finns att välja på är 18,5, 13 och 6 km
        </p>

        <h4>18,5 km</h4>
        <p>
          Banan för dig som vill ha en tuff utmaning i djupet av Hälsingeskogarna. Underlaget varierar från grus till obanat.
          Vi lämnar garanti på trötta ben!
        </p>

        <h4>13 km</h4>
        <p>
          Mellanbanan är en riktig utmaning i sann Hälsingeterräng där det beryktade Fläckberg ska bestigas.
        </p>

        <h4>6,6 km</h4>
        <p>
          Vår "sprintbana" är en lagom utmaning för alla motionärer som gillar att känna skogen under sina fötter.
        </p>

        <h4>Plats och tid</h4>
        <p>
          Loppet har både start och målgång vid Segersta OKs klubbstuga vid Björktjärn, Segersta. Det kommer att vara skyltat från bron i Segersta.
          Starten går <strong>18.30</strong>, kom i tid, för alla klasser och datumet för årets lopp är <strike>8:e juli 2021</strike> vi återkommer med datum.
        </p>

        <h4>Anmälan</h4>
        <p>
          All anmälan sker på plats. Då 4G-täckningen är bristfällig ser vi gärna att ni swishar anmälningsavgiften innan ni kommer fram.
          Kontanter går också bra men då jämna slantar för vi har ingen växelkassa.
        </p>

        <h4>Resultat</h4>
        <p>
          Alla resultat hittar du <NavLink to="/halsingetrailen/resultat">här</NavLink>!
        </p>

        <figure>
          <img src="/images/halsingetrailen_logo.png" />
          <figcaption>Hälsingetrailen 10-års jubileum</figcaption>
        </figure>
      </article>
    </>
  )
}