import { useEffect } from "react";

export default function Styrelsen() 
{
  useEffect(() => {
    document.title = "Styrelsen | Hälsinglands Multisportklubb";
    document.getElementById("main")?.scrollIntoView(  { behavior: 'smooth' })
  }, []);

  return (
    <>
      <article id="mainContent">
        <h1>Styrelsen</h1>
        <p>
          Styrelsen består av följande personer:
        </p>
        <p>
          Kennet Bolinder, Ordförande<br/>
          Jens Östberg, Vice ordförande<br/>
          Mattias Sundberg, Kassör<br/>
          Elisabet Bratt, Ledamot<br/>
          Anki Lindquist, Ledamot<br/>
          Maria Nordström, Suppleant<br/>
          Claes Jonsson, Suppleant
        </p>
      </article>
    </>
  )
}

