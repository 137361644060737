import { useEffect } from "react";

export default function Resultat() {

  useEffect(() => {
    document.title = "Resultat Hälsingetrailen | Hälsinglands Multisportklubb";
    document.getElementById("main")?.scrollIntoView(  { behavior: 'smooth' })
  }, []);

  return (
    <>
      <article id="mainContent">

        <h1>Resultat Hälsingetrailen</h1>
        <p>
          2018 och 2019 anordnades inte något lopp.
        </p>
        <p>
          <a href="/filer/resultat_halsingetrailen_2017.pdf">Hälsingetrailen 2017</a><br />
          <a href="/filer/resultat_halsingetrailen_2016.pdf">Hälsingetrailen 2016</a><br />
          <a href="/filer/resultat_halsingetrailen_2015.pdf">Hälsingetrailen 2015</a><br />
          <a href="/filer/resultat_halsingetrailen_2014.pdf">Hälsingetrailen 2014</a><br />
          <a href="/filer/resultat_halsingetrailen_2013_18k.pdf">Hälsingetrailen 2013 18k</a> |
          <a href="/filer/resultat_halsingetrailen_2013_13k.pdf">13k</a> |
          <a href="/filer/resultat_halsingetrailen_2013_6k.pdf">6k</a><br />
          <a href="/filer/resultat_halsingetrailen_2012.pdf">Hälsingetrailen 2012</a><br />
          <a href="/filer/resultat_halsingetrailen_2011.pdf">Hälsingetrailen 2011</a><br />
          <a href="/filer/resultat_halsingetrailen_2010.pdf">Hälsingetrailen 2010</a><br />
        </p>
      </article>
    </>
  )
}