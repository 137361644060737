import moment from "moment";
import { useEffect, useState } from "react";
import { useApi } from "../../api/apiProvider";
import sv from "moment/locale/sv";
import parse from "html-react-parser";

export default function Aktuellt() {

  const Api = useApi();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const callback = async () => {
      await fetchNews();
      document.title = "Aktuellt | Hälsinglands Multisportklubb";
      document.getElementById("main")?.scrollIntoView({ behavior: 'smooth' });
    }
    callback();
  }, []);

  const fetchNews = async () => {
    setLoading(true);
    return Api.get("/aktuellt").then((res) => {
      setNews(res);
      setError(false);
      return true;
    }).catch((err) => {
      setError('Kunde inte hämta aktuell information, försök igen senare!');
      console.log(err);
    }).finally(() => {
      setLoading(false);
    });
  }


  return (
    <>
      <article id="mainContent">
        <h1>Aktuellt</h1>
      </article>
      {loading && <p>Laddar...</p>}
      {error && <p className="error">{error}</p>}
      {news.length > 0 ? news.map((item, index) => {
        return <article key={index}>
          <h4>{item.title}</h4>
          <span className="meta">
            {moment.unix(item.date).format("LL")}
          </span>
          {parse(item.content)}

        </article>

      }) : <p>
        Här kommer vi att lägga upp aktuell information om vad som händer i klubben.
      </p>}
    </>
  )


}