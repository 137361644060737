import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export default function Stormorstrailen() {

  useEffect(() => {
    document.title = "Stormorstrailen | Hälsinglands Multisportklubb";
    document.getElementById("main")?.scrollIntoView({ behavior: 'smooth' })
  }, []);

  return (
    <>
      <article id="mainContent">
        <h1>Stormorstrailen</h1>
        <p><strong>1:a oktober 2023</strong></p>
        <figure>
          <img src="/images/stormorstrailen_cover.jpg" alt="Barnens trail, Stormorstrailen" />
          <figcaption>Barnens trail, Stormorstrailen</figcaption>
        </figure>
        <p>
          Stormorstrailen är ett motionslopp där anmälningsavgiften går oavkortat till Rädda Barnen i Söderhamn, storleken på avgiften bestämmer du själv!
          Det finns banor på drygt 10 respektive 6 km.
          Vi springer till största delen en <NavLink to="/leder">permanent markerad led</NavLink> som heter just Stormorstrailen, markerad med orangea skyltar.
        </p>
        <p>
          Du är så klart välkommen att bara hänga med oss också, ta en promenad, grilla en korv, umgås i stugan.
        </p>
        <p>
          Både start och mål är precis vid Hällmyra Skidstadion.<br />
          Dusch och omklädningsrum finns anslutning till start/mål.
        </p>
        <p>
          Under åren som gått har samlat in totalt runt 70000:- till Rädda Barnen i Söderhamn.
        </p>
        <h4>Tävlingsinformation</h4>
        <p>
          Längd: <strong>10km / 6km</strong><br/>
          Klasser: <strong>motion dam/herr</strong><br/>
          Datum: <strong>1:a oktober 2023</strong><br/>
          Starttid: <strong>10:00</strong><br/>
          Kostnad: <strong>50:-</strong> (Swish 123 218 45 39 märk Stormorstrailen)<br/>
          Parkering: <strong>Hällmyra Skidstadion, Söderhamn</strong>
        </p>
      </article>
    </>
  )
}