import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useApi } from "../../api/apiProvider";


export default function Medlemskap() {

  const Api = useApi();
  const [member, setMember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    document.title = "Klubben | Hälsinglands Multisportklubb";
    document.getElementById("main")?.scrollIntoView({ behavior: 'smooth' })
  }, []);

  const postForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    setLoading(true);
    Api.post("/members", data).then((res) => {
      setMember(res);
      setError(false);
    }).catch((err) => {
      setError(true);
      console.log(err);
    }).finally(() => {
      setLoading(false);
    })

  }



  return (
    <>
      <article id="mainContent">
        <h1>Medlemskap</h1>
        <p>
          Om du uppskattar det vi gör, de arrangemang vi anordnar, den tid vi lägger ned och vill hjälpa oss fortsätta med det kan du bli medlem.
        </p>
        <p>
          Utöver att ditt medlemskap hjälper oss att hålla stigarna öppna och arrangera billiga event så får du som medlem naturligtvis tävla i klubbens namn
          och vara med på alla våra gemensamma träningsaktiviteter.
        </p>
        <p>
          Medlemskap kostar 100:-/år eller 200:-/år för familj.
        </p>

        <h3>Bli medlem i hälsinglands Multisport</h3>
        <p>
          Fyll i dina uppgifter i formuläret nedanför och klicka på "Bli medlem". Du kommer då att få ett medlemsnummer som du anger när du betalar in medlemsavgiften.
          På så sätt hjälper du oss också att hålla ordning i vårt medlemsregister.
        </p>
        <p>
          Om du redan är medlem så kan du ändå använda formuläret nedan för att uppdatera dina uppgifter samt ta reda på ditt medlemsnummer om du glömt det.
        </p>

        {loading && <p>Laddar...</p>}
        {error && <p className="error">Kunde inte skapa medlemskap, försök igen senare! Om problemet kvarstår, kontakta oss på <a href="mailto:info@hälsinglandsmultisport.se">info@hälsinglandsmultisport.se</a>.</p>}

        {member ?
          <>
            <h4>Tack för att du blivit medlem {member.firstName}!</h4>
            <p>Dina medlemsuppgifter är följande: </p>
            <p>
              Medlemsnummer: <strong>{member.id}</strong> <span className="meta">(ange detta när du betalar medlemsavgiften)</span><br/>
              Medlemstyp: <strong>{member.type=='enskild' ? 'Enskild, avgift 100:-' :'Familj, avgift 200:-'}</strong><br />
              Namn: <strong>{member.firstName} {member.lastName}</strong><br />
              E-post: <strong>{member.email}</strong><br />
              Övrigt: <strong>{member.misc}</strong><br />
              
            </p>
          </>
          :
          <form method="post" onSubmit={postForm}>

            <label>Medlemstyp *</label>
            <select name="type" required defaultValue="">
              <option value="" disabled></option>
              <option value="enskild">Enskild - 100:-</option>
              <option value="familj">Familj - 200:-</option>
            </select>
            <label>Förnamn *</label>
            <input name="firstName" type="text" size="30" required placeholder="Förnamn" />
            <label>Efternamn *</label>
            <input name="lastName" type="text" size="30" required placeholder="Efternamn" />

            <label>E-post *</label>
            <input name="email" type="email" size="45" required placeholder="Giltig e-postadress" />

            <label>Övrigt</label>
            <textarea rows="4" name="misc" placeholder="Ex. familjemedlemmar" ></textarea>

            <input type="submit" name="doRegisterMember" value="Bli medlem" />

            <span className="meta">Fält markerade med * måste fyllas i.</span>

          </form>
        }
        <aside>
          <h4>Betalningssätt</h4>
          <p>
            Du kan betala via bankgiro, direktöverföring eller Swish. Glöm inte att ange ditt medlemsnummer när du betalar!
          </p>
          <p>
            Banköverföring: <strong>SWEDBANK</strong> clearing <strong>8103-4</strong> konto <strong>943 829 739-3</strong>
          </p>
          <p>
            BankGiro: <strong>395-8907</strong>
          </p>
          <p>
            Swishnr: <strong>123 218 45 39</strong>
          </p>
        </aside>

      </article>
      <nav>
        <NavLink to="/klubben">Mer om klubben</NavLink>
        <NavLink to="/klubben/historia">Historia</NavLink>
      </nav>
    </>
  )
}